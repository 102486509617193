import {
  IUser,
  IYYResponse,
  ICardInfo,
  ICardSearch,
  IPage,
  PAGE_SIZE,
  IPrintTemplateInfo,
  personItem,
  IPrintTemplateItem,
} from "@/ts";
import http from ".";
import { v4 as uuidv4 } from "uuid";

// 获得账户信息
export async function getUser(): Promise<IUser> {
  // TODO 对接平台接口
  return await {
    userId: "",
    userName: "",
  };
}

// export function getCardList<T>(param?:ICardSearch):Promise<AxiosResponse<T, any>> {
//     return axios.post<T>("/platform/ListManage/queryList", {"pageobj":{"curPage":1,"pageSize":20,"total":0},"formobj":"700436811494723584","filterobj":{}});
// }
// 获得卡片列表
export function getCardList(param: ICardSearch): Promise<IPage<ICardInfo[]>> {
  console.log(param, "paramparamparamparamparamparam");
  let unitCode = sessionStorage.getItem("unitCode");
  let rgCode = sessionStorage.getItem("rgCode");
  param.pageSize = param.pageSize ?? PAGE_SIZE;
  // param.pageNumber=param.pageNumber
  param.rgCode = rgCode ? rgCode : "";
  param.orgCode = unitCode ? unitCode : "";

  return yyQueryList<ICardInfo>("/cardsearch/list", param);
}

// 获得打印模版信息
export function getPersonList(): Promise<IPrintTemplateInfo> {
  return platformGetInfo<IPrintTemplateInfo>(
    "/basedata/queryPop/basedata_personnel"
  );
}

// 获得打印模版信息
export function getPrintTemplateInfo(): Promise<IPrintTemplateInfo> {
  let orgId = sessionStorage.getItem("orgId");
  console.log(orgId, "orgId");
  const param = {
    orgId: orgId,
  };
  return platformGetInfo<IPrintTemplateInfo>(
    "/Barcodes/findModelMaster",
    param
  );
}

// 获得打印模版项信息
export function getPrintTemplateItem(): Promise<IPrintTemplateItem[]> {
  let orgId = sessionStorage.getItem("orgId");

  const param = {
    orgId: orgId,
  };
  return platformGetInfo<IPrintTemplateItem[]>(
    "/Barcodes/findModelDetails",
    param
  );
}

// 保存打印模版信息
export function savePrintTemplateInfo(
  param: IPrintTemplateInfo
): Promise<string> {
  return platformPost<string>("/Barcodes/saveOrUpdate", param);
}

// SDk 资产云2.0 获取单位orgId
export function sdkGetPersonId(userId: any): Promise<IPrintTemplateItem[]> {
  // const param={
  //     userId: 1272914195944853617,
  // };
  return platformGetInfo<IPrintTemplateItem[]>(
    "/pdmanage/assets/person?userId=" + userId.userId
  );
}
// YY通用处理方法
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function yyQueryList<T>(url: string, param?: any): Promise<IPage<T[]>> {
  // const res=await http.post<IPage<T[]>>("/api-service"+url, param);
  const res = await http.post<IPage<T[]>>(
    "/pandian/pdmanage/app/assetcloud/api-service" + url,
    param
  );

  if (res) {
    return res;
  } else {
    return {
      content: [],
      // 当前叶数
      number: param?.pageNumber ? parseInt(param?.pageNumber) : 1,
      // 每页记录数
      numberOfElements: param?.pageSize ? parseInt(param?.pageSize) : PAGE_SIZE,
      // 总记录数
      totalElements: 0,
      // 总页数
      totalPages: 1,
    };
  }
}

async function platformGetInfo<T>(url: string, param?: any): Promise<T> {
  const res = await http.get<T>(
    "/pandian/pdmanage/app/assetcloud/platform" + url,
    param
  );
  if (res) {
    return res;
  } else {
    return <T>{};
  }
}

async function platformPost<T>(url: string, param?: any): Promise<T> {
  const res = await http.post<T>(
    "/pandian/pdmanage/app/assetcloud/platform" + url,
    param
  );
  if (res) {
    return res;
  } else {
    return <T>{};
  }
}

//获取人员接口
// 保存打印模版信息
export function getUserPostInfo(param: personItem): Promise<string> {
  return getDeptPost<string>("/getBaseData/getUserByOrgid", param);
}
//获取部门接口
// 保存打印模版信息
export function getDeptPostInfo(param: personItem): Promise<string> {
  return getDeptPost<string>("/getBaseData/getDeptByOrgid", param);
}

//获取资产分类
// 保存打印模版信息
export function getZCInfo(param: personItem): Promise<string> {
  return getDeptPost<string>("/getBaseData/getZiChanFL", param);
}
//卡片更新
export function getUpdateInfo(param: personItem): Promise<string> {
  return getDeptPost<string>("/IC/UpdateCardInfo", param);
}

async function getDeptPost<T>(url: string, param?: any): Promise<T> {
  const res = await http.post<T>("/api-service-se" + url, param);
  if (res) {
    return res;
  } else {
    return <T>{};
  }
}

