// ==================================== 静态数据
// 路由数据
import routerData from "./data/model/routerData"
// 首页菜单数据
import homeMenuData from "./data/model/homeMenuData"
export * from "./data/model/homeMenuData"

// ==================================== 常量数据
// 
export const PAGE_SIZE = 20
// ==================================== 枚举类型
export * from "./enums/httpEnum"
// ==================================== 字典
export * from "./data/model/dict"

// ==================================== 类型
export * from "./type/base"
export * from "./type/system"
export * from "./type/mayi"

// ==================================== 工具方法
export * from "./utils"
export * from "./utils/loading"
export * from "./utils/is"

// ==================================== hooks
export * from "./hooks/useAuthButtons"
export * from "./hooks/useHandleData"

// ==================================== api接口数据
export * from "./data/api/system"

// ==================================== 蚂蚁api接口数据
export * from "./data/api/gateway"
export * from "./data/api/label"

export {routerData}
export {homeMenuData}
// export {IRouterData}
// export {ISystemState, IUser, IMenuItem}
// export {getUser}