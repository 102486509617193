
import { IMYPage, PAGE_SIZE } from "@/ts";
import http from ".";

// 蚂蚁通用处理方法
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function queryList<T>(url:string, param?:any):Promise<IMYPage<T>> {
    const res=await http.get<IMYPage<T>>("/api"+url, param);
    if(res) {
        return res;
    }else {
        return <IMYPage<T>>{
            list: [],
            // 当前叶数
            current: param?.current ?? 1,
            // 每页记录数
            pageSize: param?.pageSize ?? PAGE_SIZE,
            // 总记录数
            total: 0,
            errorCode:"",
            errorMsg:"",
            success:false,
        };
    }
}

// 蚂蚁通用处理方法
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function queryListPost<T>(url:string, param?:any):Promise<IMYPage<T>> {
    const res=await http.post<IMYPage<T>>("/api"+url, param);
    if(res) {
        return res;
    }else {
        return <IMYPage<T>>{
            list: [],
            // 当前叶数
            current: param?.current ?? 1,
            // 每页记录数
            pageSize: param?.pageSize ?? PAGE_SIZE,
            // 总记录数
            total: 0,
            errorCode:"",
            errorMsg:"",
            success:false,
        };
    }
}

// 蚂蚁通用处理方法 管理标签新增
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function post<T>(url:string, row?:any):Promise<T> {
    const res=await http.post<T>("/api"+url, row);
    if(res) {
        return res;
    }else {
        return <T>{
        };
    }
}
// 蚂蚁通用处理方法 管理标签新增
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function get<T>(url:string, row?:any):Promise<T> {
    const res=await http.get<T>("/api"+url, row);
    if(res) {
        return res;
    }else {
        return <T>{
        };
    }
}


