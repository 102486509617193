// 资产打码和资产盘点的table上放的按钮ts组件数据
// 通过id: "1"资产打码 id: "2"资产盘点
import { IButton, IMenuItem } from '@/ts'
import router from "@/router/index"
import axios from "axios";

export default <IMenuItem[]>[
    {
        id: "1", name: 'print', title: '资产打码', icon: "", children: [
            {
                id: "1", name: '', title: '业务办理', icon: "", children: [
                    // { id: "101", name: 'CardList', title: "资产打码", icon: "icontongjichaxun", url:"/print/CardList",
                    //     btns:<IButton[]>[
                    //         {active:true,name:"RFIDPrint",title:"二维码打印",icon:"Printer", type:"primary",onClick:()=>{window.print()}},
                    //         {active:true,name:"PrintSet1",title:"RFID打印",icon:"Printer",model:"Dialog", routerPath:"/print/PrintSet"},
                    //         {active:true,name:"PrintSet2",title:"蓝牙标签打印",icon:"Printer",model:"Dialog", routerPath:"/print/PrintSet"},
                    //         {active:true,name:"PrintSet5",title:"停止打印",icon:"DocumentDelete",model:"Dialog", routerPath:"/print/PrintSet"},
                    //         {active:true,name:"PrintSet",title:"打印设置",icon:"Setting",model:"Dialog", routerPath:"/print/PrintSet"},
                    //         {active:true,name:"PrintTemplateSet",title:"打印模版设置",model:"Dialog",icon:"Setting"},
                    //         {active:true,name:"PrintSet3",title:"清空打印次数",icon:"Delete",model:"Dialog", routerPath:"/print/PrintSet"},
                    //     ]
                    // },
                    {
                        id: "103", name: 'cardList', title: "资产打码", icon: "icontongjichaxun", url: "/base/BaseList",
                        btns: <IButton[]>[
                            { active: true, name: "RFIDPrint", title: "二维码打印", icon: "Printer", model: "DIALOG", routerPath: "/print/previewClick" },
                            { active: true, name: "PrintSet1", title: "RFID打印", icon: "Printer", model: "DIALOG", routerPath: "/print/previewClick" },
                            // {active:true,name:"PrintSet1",title:"RFID打印",icon:"Printer",model:"Dialog"},
                            { active: true, name: "PrintSet2", title: "条码打印", icon: "Printer", model: "Dialog", routerPath: "/print/PrintSet"},
                           /*  { active: true, name: "PrintSet5", title: "停止打印", icon: "DocumentDelete", model: "Dialog", routerPath: "/print/PrintSet", onClick: () => { stopPrint() } }, */
                            /* { active: true, name: "PrintSet", title: "打印设置", icon: "Setting", model: "Dialog", routerPath: "/print/PrintSet" }, */
                            { active: true, name: "PrintTemplateSet", title: "打印模版设置", model: "DIALOG", routerPath: "/print/QrPrint", icon: "Setting" },
                            { active: true, name: "PrintSet3", title: "清空打印次数", icon: "Delete", model: "Dialog", routerPath: "/print/PrintSet" },
                        ]
                    },
                    { id: "102", name: 'TaoBao', title: "统计视图", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/index.html#/asset_management/asset_monitor" }
                ]
            },
        ]
    },
    {
        id: "2", name: 'check', title: '资产盘点', icon: "", children: [
            {
                id: "1", name: '', title: '资产盘点', icon: "", children: [
                    { id: "201", name: 'sendPand', title: "发起盘点", icon: "icontongjichaxun", url: "https://zams.assetcloud.zj.gov.cn/platform/slogin/defalut/pandiantaskDW?isCardRelated=1&parentId=749999610650431488&tenant=jgd&userId=1272914195944543983&tankuang=acttodoDBTK" },
                    { id: "203", name: 'assetMap', title: "资产地图", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/index.html#/assets_visualization/mapContainer" },
                    { id: "203", name: 'asset3DView', title: "资产可视化", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/index.html#/assets_visualization/asset_space" }
                ]
            },
            {
                id: "2", name: '', title: '盘点初始化', icon: "", children: [
                    {
                        id: "201", name: 'gatewayManage', title: "网关管理", icon: "icontongjichaxun", url: "/base/BaseList",
                        btns: <IButton[]>[
                            { active: true, name: "gatewayAdd", title: "添加网关", icon: "Plus", type: "primary", model: "EVENT", routerPath: "Add" },
                            { active: true, name: "gatewayListAdd", title: "从已注册网关添加", icon: "Plus", model: "DIALOG", routerPath: "/print/PrintSet" },
                            { active: true, name: "gatewaySetting", title: "网关配置", icon: "Setting", model: "DIALOG", routerPath: "/print/PrintSet" },
                        ]
                    },
                    {
                        id: "202", name: 'labelManage', title: "标签管理", icon: "icontongjichaxun", url: "/base/BaseList",
                        btns: <IButton[]>[
                            { active: true, name: "labelExp", title: "全部导出", icon: "Download", model: "DIALOG", routerPath: "/print/PrintSet" },
                            { active: true, name: "labelImp", title: "批量导入", icon: "Upload", model: "DIALOG", routerPath: "/print/PrintSet" },
                            { active: true, name: "labelSetting", title: "标签配置", icon: "Setting", model: "DIALOG", routerPath: "/print/PrintSet" },
                            { active: true, name: "labelAdd", title: "添加标签", icon: "Plus", type: "primary", model: "EVENT", routerPath: "Add" },
                            { active: true, name: "labelListAdd", title: "从已注册标签添加", icon: "Plus", type: "primary", model: "EVENT", routerPath: "Add" },
                        ]
                    },
                    { id: "203", name: 'Dynamic', title: "资产监控", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/index.html#/asset_management/asset_monitor" },
                    { id: "204", name: 'Dynamic1', title: "智能预警", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/index.html#/intelligent_warning" },
                    { id: "203", name: 'Dynamic2', title: "盘点机管理", icon: "icontongjichaxun", url: "https://zkassetplatform.antgroup.com/" }
                    //账户管理，版本管理，开始时间，到期时间，是否多设备
                ]
            }
        ]
    }
]
//默认菜单
export function getDefaultMenu(): IMenuItem {
    // console.log(router.currentRoute.value.name);
    return <IMenuItem>{
        id: "0",
        name: "main",
        title: router.currentRoute.value.meta?.title,
        icon: "icontongjichaxun",
        url: "/Main",
    }
}