import { EnumProps } from "@/components";

// 获得在线状态
export const getIsAliveEnum = (): EnumProps[] => {
  return [
    { label: "在线", value: 1 },
    { label: "离线", value: 0 },
  ];
};
// 网关类型
export const getTypeEnum = (): EnumProps[] => {
  return [{ label: "蓝牙设备", value: "BLUETOOTH" }];
};
// 所属单位
export const getOrgEnum = (): EnumProps[] => {
  return [{ label: "杭州慧希金", value: "E75F9EE2F" }];
};
export const getOrgLocationEnum = (): EnumProps[] => {
  return [
    {
      value: "实验楼",
      label: "实验楼",
      children: [
        {
          value: "二楼",
          label: "二楼",
          children: [
            {
              children: [
                { label: "实验楼二楼资产云214", value: "cdbc49de" },
                { label: "实验楼二楼资产云216", value: "cb578036" },
              ],
              label: "资产云",
              value: "资产云",
            },
            {
              children: [{ label: "实验楼二楼1号机房", value: "e28be89a" }],
              label: "1号机房",
              value: "1号机房",
            },
          ],
        },
      ],
    },
    {
      value: "教学楼",
      label: "教学楼",
      children: [
        {
          value: "5楼",
          label: "5楼",
          children: [
            {
              children: [
                { label: "教学楼5楼教学区510教室", value: "909a1832" },
                { label: "教学楼5楼教学区502教室", value: "30bbe8a0" },
                { label: "教学楼5楼教学区501教室", value: "5730b068" },
              ],
              label: "教学区",
              value: "教学区",
            },
          ],
        },
        {
          value: "3楼",
          label: "3楼",
          children: [
            {
              children: [
                { label: "教学楼3楼教师办公室301", value: "63042f59" },
              ],
              label: "教师办公室",
              value: "教师办公室",
            },
          ],
        },
      ],
    },
  ];
};

// 获得绑定状态
export const getBindingStatusEnum = (): EnumProps[] => {
  return [
    { label: "已绑定", value: 1 },
    { label: "未绑定", value: 0 },
  ];
};
export const getLabelEnum = (): EnumProps[] => {
  return [{ label: "ERD", value: "ERD" }];
};
