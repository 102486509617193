//创建vue实例
import { createApp } from "vue";
// 引入App.vue
import App from "./App.vue";
// 引入路由对象
import router from "@/router";
// 引入store vuex对象
import store from "@/store";
// 引入区域打印
import print from "vue3-print-nb";
// 全局引入ElementPlus
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
// 引入静态资源里面的字体样式
import "@/assets/font/iconfont.css";
import "@/assets/css/index.scss";
import "element-plus/dist/index.css";

//创建app应用对象
const app = createApp(App);
// 遍历对象
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
  .use(ElementPlus, {
    locale: zhCn,
  }) //use(ElementPlus)实例对象,设置中文
  .use(store) //use(store)实例对象
  .use(router) //use(router)实例对象
  .use(print) //.use(print)打印
  .mount("#app"); //挂载app
