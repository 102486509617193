import { ElLoading } from "element-plus";

let loadingInstance: ReturnType<typeof ElLoading.service>;

const createLoading = ()=>{
    loadingInstance = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: "Loading",
        background: "rgba(0,0,0,0.5)"
    });
};

const destroyLoading=()=>{
    loadingInstance.close();
}

let needLoadingRequestCount = 0;
export function openFullScreenLoading():void {
    if(needLoadingRequestCount === 0) {
        createLoading();
    }
    needLoadingRequestCount++;
}

export function closeFullScreenLoading():void {
    if(needLoadingRequestCount <= 0) {
        return;
    }
    needLoadingRequestCount--;
    if(needLoadingRequestCount === 0) {
        destroyLoading();
    }

}