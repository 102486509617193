import { IMYGatewaySearch, IMYPage, PAGE_SIZE, IMYGateway } from "@/ts";
import { queryList, post } from "./mayiCommon";
import { v4 as uuidv4 } from "uuid";
// 获得网关列表
export function getGatewayManage(
  param: IMYGatewaySearch
): Promise<IMYPage<IMYGateway>> {
  param.limit = param.limit ?? PAGE_SIZE;
  return queryList<IMYGateway>("/gateway/manage/query", param);
}
// 网关新增
export function addGatewayManage(row: IMYGateway): Promise<boolean> {
  const rowList = {
    deviceId: row.deviceId,
    type: row.type,
    bluetoothMacAddress: row.bluetoothMacAddress,
    instCode: row.org,
    areaId: "cb578036",
    bizId: uuidv4(),
  };
  return post<boolean>("/gateway/manage/add",rowList);
}

// 网关编辑
export function updateGatewayManage(row: IMYGateway): Promise<boolean> {
    const rowList = {
        deviceId: row.deviceId,
        type: row.type,
        bluetoothMacAddress: row.bluetoothMacAddress,
        instCode: row.org,
        areaId: "cb578036",
        bizId: uuidv4(),
      };
  return post<boolean>("/gateway/manage/update", rowList);
}
// 管理标签删除
export function deleteGatewayManage(ids: string[]): Promise<boolean> {
  return post<boolean>("/gateway/manage/delete", {
    bizId: uuidv4(),
    deviceIdList: ids,
  });
}
