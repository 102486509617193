// 请求配置
export enum RequestEnum {
    // 请求超时时间
    TIMEOUT = 30000,
}

// 响应配置
export enum ResponseEnum {
    // 成功
	SUCCESS = 200,
    // 服务器异常
	ERROR = 500,
    // 认证失败
	OVERDUE = 401,
}