// axios二次封装
import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  getSign,
  IYYResponse,
  RequestEnum,
  ResponseEnum,
  openFullScreenLoading,
  closeFullScreenLoading,
} from "@/ts";
import { ElMessage } from "element-plus";
import router from "@/router/index";
import qs from "qs";

// axios.defaults.baseURL = 'http://192.168.1.188:7002';
// axios.defaults.baseURL = 'https://zkassetplatform.antgroup.com';

const config: AxiosRequestConfig = {
  // 设置请求超时时间（10s）
  timeout: RequestEnum.TIMEOUT as number,
  // 跨域时允许携带凭证
  withCredentials: true,
};
const checkState = (status: number): boolean => {
  if (status == ResponseEnum.SUCCESS) return true;

  switch (status) {
    case 400:
      ElMessage.error("请求失败！请您稍后重试");
      break;
    case 401:
      ElMessage.error("认证失效！请您重新获取认证");
      break;
    case 403:
      ElMessage.error("当前账号无权限访问！");
      break;
    case 404:
      ElMessage.error("你所访问的资源不存在！");
      router.replace("/404");
      break;
    case 405:
      ElMessage.error("请求方式错误！请您稍后重试");
      break;
    case 408:
      ElMessage.error("请求超时！请您稍后重试");
      break;
    case 500:
      ElMessage.error("服务异常！");
      break;
    case 502:
      ElMessage.error("网关错误！");
      break;
    case 503:
      ElMessage.error("服务不可用！");
      break;
    case 504:
      ElMessage.error("网关超时！");
      break;
    default:
      ElMessage.error("请求失败！");
  }

  if (status != 404 && !window.navigator.onLine) router.replace("/500");
  return false;
};

class RequestHttp {
  http: AxiosInstance;

  public constructor(config: AxiosRequestConfig) {
    // 实例化axios
    this.http = axios.create(config);

    // 添加请求拦截器
    // 请求拦截
    this.http.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // 如果当前请求不需要显示 loading,在 api 服务中通过指定的第三个参数: { headers: { noLoading: true } }来控制不显示loading
        config.headers!.noLoading || openFullScreenLoading();
        if (
          config &&
          config.url?.indexOf("/pandian/pdmanage/app/assetcloud") == 0
        ) {
          //添加验签信息
          config.headers["token"] = sessionStorage.getItem("tokenID");
        } else if (config && config.url?.indexOf("/api/") == 0) {
          // 蚂蚁接口处理
          // config.headers['Cookie']='JSESSIONID=4b62658e-8fff-4f52-8ec9-4bed744be851; Path=/; HttpOnly; SameSite=lax';
          // config.headers['Cookie']='IM_LOGIN_ENTITY=1b66805b4e324ba3a195a7ed53b8d97e; HttpOnly; Secure; SameSite=None; Max-Age=31536000; Expires=Tue, 19 Mar 2024 03:22:10 GMT; Domain=.antgroup.com; Path=/';
        } else if (config && config.url?.indexOf("/api-service-se") == 0) {
          // debugger
          config.headers["authorization"] = "2a4ad430beb311ecb84b6805cab640b8";
        } else if (config.method === "post") {
          config.data = qs.stringify(config.data);
        }
        console.log(config);
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    // 响应拦截
    this.http.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response;
        // 关闭请求loading
        closeFullScreenLoading();
        // 认证失败（code == 410）
        if (data.code && data.code == ResponseEnum.OVERDUE) {
          ElMessage.error(data.msg);
          //处理认证失败时如何跳转页面
          return Promise.reject(data);
        }
        // YY其他异常
        if (data.code && data.code != ResponseEnum.SUCCESS) {
          ElMessage.error(data.msg);
          return Promise.reject(data);
        }
        /* else if(!data.success) {
                // 蚂蚁其他异常
                ElMessage.error(data.resultMsg);
                return Promise.reject(data);
            } */

        if (data?.code && !checkState(data.code)) {
          return Promise.reject(data);
        }
        if (data?.type && data.type != "SUC") {
          return Promise.reject("未知异常");
        }

        if (data?.data) {
          return data.data;
        } else if (data?.result) {
          return data?.result;
        }
        if (!data.success) {
          // 蚂蚁其他异常
          ElMessage.error(data.resultMsg);
          return Promise.reject(data);
        }
        return data;
      },
      async (err: AxiosError) => {
        closeFullScreenLoading();
        if (err.message.indexOf("timeout") !== -1)
          ElMessage.error("请求超时！请您稍后重试");
        if (err.message.indexOf("Network Error") !== -1)
          ElMessage.error("网络错误！请您稍后重试");

        if (err.response) checkState(err.response.status);
        return Promise.reject(err);
      }
    );
  }

  // 常用请求方法封装
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<T>(url: string, params?: any, config = {}): Promise<T> {
    return await this.http.get(url, { params, ...config });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(url: string, params?: any, config = {}): Promise<T> {
    return this.http.post(url, params, config);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // put<T>(url: string, params?: any, config = {}): Promise<IYYResponse<T>> {
  // 	return this.http.put(url, params, config);
  // }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // delete<T>(url: string, params?: any, config = {}): Promise<IYYResponse<T>> {
  // 	return this.http.delete(url, { params, ...config });
  // }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  download(url: string, params?: any, config = {}): Promise<BlobPart> {
    return this.http.post(url, params, { ...config, responseType: "blob" });
  }
}
export default new RequestHttp(config);
