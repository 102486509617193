import {IRouterData} from '@/ts'
// 该处是路由嵌套最底层,资产打印和资产盘点ts数据组件
export default <IRouterData[]>[
    // {name:'login', title:'登录页', path:'/',component:()=>{import('../../../views/login/index.vue')}},

    // {name:'home', title:'首页', path:'/',component:'/Home'},
    {name:'print', title:'打印', path:'/print',component:'/Home'},
    {name:'check', title:'盘点', path:'/check',component:'/Home'},
    {name:'err500', title:'500', path:'/500',component:'/exception/500'},
    {name:'err404', title:'404', path:'/404',component:'/exception/404'},
    // {name:'CardList', title:'资产总帐', path:'/CardList',component:'/print/CardList'},
    // {name:'IFrame', title:'内嵌内容', path:'/IFrame',component:'/base/IFrame'},
]