//vue3中createRouter构建路由实例/createWebHistory方法/RouteRecordRaw类型别名
import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from "vue-router";
// 引入ts定义数据,数据从ts中
import { IRouterData, routerData, getComponent } from "@/ts";
import Home from "@/views/_Home.vue";

// 定义routes路由数组,定义数组类型
const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "login",
  //   component: Home,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];
//定义loadRouterData方法,将routerDats数据进行遍历,push到routes中进行导出
const loadRouterData = (routerDatas: IRouterData[]) => {
  routerDatas.forEach((item) => {
    routes.push({
      path: item.path, //路由地址
      name: item.name, //路由名称
      meta: item,
      // component: ()=>Promise.resolve(getComponent(item))
      component: getComponent(item), //需要按需引入的路径
      // });
    });
    // 子项路由进行children和长度判断入loadRouterData方法
    if (item.children && item.children.length) {
      loadRouterData(item.children);
    }
  });
};
// 调用loadRouterData方法,传(routerData)
loadRouterData(routerData);
console.log(routes,'ddd')
// createRouter构建实例
const router = createRouter({
  // history模式 createWebHistory方法
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes, // routes路由配置,简写:routes:routes
});

// var isF = false; //是用来判断动态路由是否被获取
// router.beforeEach(async (to, from)=>{
//   if (to.path == "/login") {
//     return true;
//   }
//   if (!getToken()) {
//     return { path: "/login" };
//   } else {
//     if (isF) {
//       return true;
//     } else {
//       let add = store.getters.menuList || "";
//       routerData(add);
//       isF = true;
//       return { ...to, replace: true };
//     }
//   }
// });

//导出路由对象
export default router;
