import { IMYLabelSearch, IMYPage,IMYTree, PAGE_SIZE, IMYLabel } from "@/ts";
import { queryListPost, post,get } from "./mayiCommon";
import { v4 as uuidv4 } from "uuid";
// 获得标签列表
export function getLabelManage(
  param: IMYLabelSearch
): Promise<IMYPage<IMYLabel>> {
  param.limit = param.limit ?? PAGE_SIZE;
  return queryListPost<IMYLabel>("/label/manage/listPage", param);
}
// 管理标签新增
export function addLabelManage(row: IMYLabel): Promise<boolean> {
    // 新增后端要求的格式
  const rowList = {
    instCode: row.org, //标签所属单位id
    initAreaId: row.area[row.area.length-1], //初始位置ID
    safeAreaIds: [row.area[row.area.length-1]], //安全位置id列表
    bizId: uuidv4(),
    deviceBasicInfoList: [
      {
        deviceId: row.deviceId,
        type: "QR",
      },
    ],
  };
  return post<boolean>("/label/manage/add", rowList);
}
// 管理标签编辑
export function updateLabelManage(row: IMYLabel): Promise<boolean> {
    // 编辑后端要求的数据格式
 const rowList = {
    instCode: row.org, //标签所属单位id
    initAreaId: row.area[row.area.length-1], //初始位置ID
    safeAreaIds: [row.area[row.area.length-1]], //安全位置id列表
    bizId: uuidv4(),
    deviceId: row.deviceId,
    type: "QR",
  }; 
  return post<boolean>("/label/manage/update",rowList);
}

// 管理标签删除
export function deleteLabelManage(ids: string[]): Promise<boolean> {
  return post<boolean>("/label/manage/delete", {
    bizId: uuidv4(),
    deviceIds: ids,
  });
}

// 管理标签当前位置树形图接口
export function selectTreeApi():Promise<IMYTree> {
    return get<IMYTree>('/area/tree/query');
}
