import { IRouterData,IMenuItem,IButton,isArray } from "@/ts";
import {defineAsyncComponent, ComponentPublicInstance, Component, markRaw} from "vue"
import CryptoJS from 'crypto-js'

const noData='';

// 获得懒加载组件
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComponent(data:IRouterData|IMenuItem|IButton):any{
    let comp=(data as IRouterData).component;
    if(!comp||!comp.length) {
        comp=(data as IMenuItem).url;
    }
    if(!comp||!comp.length) {
        comp=(data as IButton).routerPath as string | Component;
    }
    
    /* if(comp&&(comp as string).toUpperCase() === 'ADD') {
        return ()=>import("@/components/RInputForm/index.vue");
    }else  */if(isURL(comp as string)) {
        comp="/base/IFrame";
    }else if(!comp||!comp.length) {
        comp="/Home";
    }
    return ()=>import("@/views"+comp+".vue");
}
// 获得异步组件
export function getAsyncComponent<T extends Component = {
    new (): ComponentPublicInstance;
}>(data:IRouterData|IMenuItem|IButton):T{

    return markRaw(defineAsyncComponent(getComponent(data)));
}

// 菜单转换为路由
export function toRouterDataConvert(mItem:IMenuItem):IRouterData {
    return <IRouterData>{
        name:mItem.name,
        title:mItem.title,
        path:mItem.url,
        component:mItem.url,
        menuItem:mItem
    }
}

// 判断是否合法url
function isURL(strUrl:string):boolean {// 验证url
    // const strRegex = "^((https|http|ftp|rtsp|mms)?://)"
    // + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" // ftp的user@
    // + "(([0-9]{1,3}\\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
    // + "|" // 允许IP和DOMAIN（域名）
    // + "([0-9a-z_!~*'()-]+\\.)*" // 域名- www.
    // + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\\." // 二级域名
    // + "[a-z]{2,6})" // first level domain- .com or .museum
    // + "(:[0-9]{1,4})?" // 端口- :80
    // + "((/?)|" // a slash isn't required if there is no file name
    // + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
    const strRegex="^http(s)?:\\/\\/([\\w-]+\\.)+[\\w-]+(\\/[\\w- .\\/?%&=#]*)?$";
    const re = new RegExp(strRegex);
    return re.test(strUrl);
}

/**
 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
 * @param {String} prop 当前 prop
 * @return string
 * */
export function handleProp(prop: string) {
	const propArr = prop.split(".");
	if (propArr.length == 1) return prop;
	return propArr[propArr.length - 1];
}

/**
 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
 * @param {String} callValue 当前单元格值
 * @param {Array} enumData 字典列表
 * @param {Array} fieldNames 指定 label && value 的 key 值
 * @param {String} type 过滤类型（目前只有 tag）
 * @return string
 * */
export function filterEnum(
	callValue: any,
	enumData: any[] | undefined,
	fieldNames?: { label: string; value: string },
	type?: string
): string {
	const value = fieldNames?.value ?? "value";
	const label = fieldNames?.label ?? "label";
	let filterData: { [key: string]: any } = {};
	if (Array.isArray(enumData)) filterData = enumData.find((item: any) => item[value] === callValue);
	if (type == "tag") return filterData?.tagType ? filterData.tagType : "";
	return filterData ? filterData[label] : noData;
}

/**
 * @description 处理无数据情况
 * @param {String} callValue 需要处理的值
 * @return string
 * */
export function formatValue(callValue: any) {
	// 如果当前值为数组,使用 / 拼接（根据需求自定义）
	if (isArray(callValue)) return callValue.length ? callValue.join(" / ") : noData;
	return callValue ?? noData;
}

/**
 * @description 处理 prop 为多级嵌套的情况(列如: prop:user.name)
 * @param {Object} row 当前行数据
 * @param {String} prop 当前 prop
 * @return any
 * */
export function handleRowAccordingToProp(row: { [key: string]: any }, prop: string) {
	if (!prop.includes(".")) return row[prop] ?? noData;
	prop.split(".").forEach(item => (row = row[item] ?? noData));
	return row;
}

// 签名算法
export function getSign(data:any,ts:number,appid:string,nonce:number,reqUrl:string):string {
    // debugger
    const paramArr=parseParam(data,appid,nonce,ts,reqUrl);
    let signStr = paramArr.map(m=>m.join('=')).join('&');

    console.log("before signStr:",signStr);
    const hash = CryptoJS.HmacSHA256(signStr, '5de4116b807c6d4b219f4f527d9d45f5');
    
    signStr = hash.toString();
    console.log("after signStr:",signStr);
    return signStr;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseParam(bodyObj:any,appid:string,nonce:number,ts:number,reqUrl:string) {
    const paramArr=[];
    // 解析body
    for (const key in bodyObj) {
        if (Object.hasOwnProperty.call(bodyObj, key)) {
            const value = bodyObj[key];
            console.log(value, typeof(value), key);
            if(typeof(value) === "string" && value.indexOf("{{") != -1) {
                // const _val = pm.environment.get(value.replace(new RegExp('(\{|\})', "g"),""));
                // paramArr.push([key,_val]);
            }else if (Array.isArray(value)) {
                paramArr.push([key, formatArr(value)]);
            } else if(!Array.isArray(value)){
                paramArr.push([key,value.toString()]);
            }else {
                console.log('====',key, value, paramArr);
                for (const skey in value) {
                    const svalue = value[skey];
                    console.log('---1', skey, svalue, typeof(svalue));
                    if(typeof(svalue) === "string" && svalue.indexOf("{{") != -1) {
                        // const _sval = pm.environment.get(svalue.replace(new RegExp('(\{|\})', "g"),""));
                        // paramArr.push([skey,_sval]);
                    }else if(typeof(svalue) === "object"){
                        for (const skey1 in svalue) {
                            const svalue1 = svalue[skey1];
                            if(typeof(svalue1) === "string" && svalue1.indexOf("{{") != -1) {
                                // const _sval1 = pm.environment.get(svalue1.replace(new RegExp('(\{|\})', "g"),""));
                                // paramArr.push([skey1,_sval1]);
                            }else if(!Array.isArray(svalue1)){
                                paramArr.push([skey1,svalue1.toString()]);
                            }else{
                                paramArr.push([skey1,formatArr(svalue1)]);
                            }
                        }
                    }else if(!Array.isArray(svalue)){
                        paramArr.push([skey,svalue.toString()]);
                    }else{
                        paramArr.push([skey,formatArr(svalue)]);
                    }
                }
            }
        }
    }
    paramArr.push(["appid",appid]);
    paramArr.push(["nonce",nonce]);
    paramArr.push(["timestamp",ts]);
    paramArr.push(["reqUrl", reqUrl]);

    console.log(paramArr, bodyObj,'ccccccccccccccccccccc');
    // console.log(paramArr,'ccccccccccccccccccccc');
    //按字母排序
    paramArr.sort();
    return paramArr;
}

// 解析body
//格式化数组中的对象
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatArr(arr: any[]):string{
    // console.log('formatArr..');
    if(arr.length > 0){
        //获取对象的所有属性
        const header = Object.keys(arr[0]);
        // 排序
        header.sort();
        let str = '[';
        for(let i=0;i<arr.length;i++){
            if(i>0){
                str += ',';
            }
            str += '{';
            for(let j = 0;j<header.length;j++){
                if(j>0){
                    str += ',';
                }
                if(typeof(arr[i][header[j]]) === "number") {
                    str += '"'+header[j]+'":' + arr[i][header[j]] + '';
                }else {
                    str += '"'+header[j]+'":"' + arr[i][header[j]] + '"';
                }
            }
            str += '}';
        }
        str += ']';
        return str;
    }else{
        return JSON.stringify(arr);
    }
}