import {RouteMeta} from "vue-router"
import {Component} from "vue"
import {EpPropMergeType} from "element-plus/es/utils"
import {FormRules} from "element-plus"
import {FilterMethods,Filters } from "element-plus/es/components/table/src/table-column/defaults"
import {ColumnProps} from "@/components"



// 路由类型
export interface IRouterData extends RouteMeta {
    path:string,
    name:string,
    title:string,
    component:string|Component,
    menuItem?:IMenuItem,
    children?:IRouterData[]
}

export enum PageOpenWith{
    DIALOG="DIALOG",
    EVENT="EVENT"
}

// 按钮类型
export interface IButton {
    name:string,
    title:string,
    active:boolean,
    icon:string,
    type?: EpPropMergeType<StringConstructor, "" | "default" | "primary" | "success" | "warning" | "info" | "danger" | "text", unknown>,
    routerPath?:string|Component,
    model?:PageOpenWith,
    onClick?: ((evt: MouseEvent) => any) | undefined
}

// 首页菜单项
export interface IMenuItem {
    readonly id:string,
    readonly name:string,
    readonly title:string,
    readonly icon:string,
    readonly url:string,
    component?:string|Component,
    color?:string,
    tagger?:boolean,
    // 菜单下的功能按钮
    btns?:IButton[],
    children?:IMenuItem[]
}

// 分页信息
export interface IPage<T> {
    content: T,
    // 当前叶数
    number: number,
    // 每页记录数
    numberOfElements: number,
    // 总记录数
    totalElements: number,
    // 总页数
    totalPages: number
}

// 表格
export interface ITable {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowKey?:string | ((row: any) => string),
    columns:ITableColumn[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getData:(currentPage:number,pageSize:number,param:any)=>Promise<IPage<any[]>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?:any[] | undefined,
    // height?:string|number,
    // paging:IPagination
}

// 表头
export interface ITableColumn {
    type?: string;
    label?: string;
    className?: string;
    labelClassName?: string;
    property?: string;
    prop?: string;
    width?: string | number;
    minWidth?: (string | number);
    sortable?: (boolean | string);
    showOverflowTooltip?:boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortMethod?: (a: any, b: any) => number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortBy?: string | string[] | ((row: any, index: number) => string);
    resizable?: boolean;
    columnKey?: string;
    align?: string;
    headerAlign?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectable?: (row: any, index: number) => boolean;
    reserveSelection?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterMethod?: FilterMethods<any>;
    filteredValue?: string[];
    filters?: Filters;
    filterPlacement?: string;
    filterMultiple?: boolean;
    index?: number | ((index: number) => number);
    sortOrders?: ("ascending" | "descending" | null)[];
}

// 分页
export interface IPagination {
    readonly total?: number;
    pageSize?: number;
    readonly defaultPageSize?: number;
    currentPage?: number;
    readonly defaultCurrentPage?: number;
    readonly pageCount?: number;
    readonly small?: boolean;
    readonly disabled?: boolean;
    readonly popperClass?: string;
    readonly layout?: string;
    readonly background?: boolean;
    readonly pagerCount?: number;
    readonly pageSizes?: number[];
    readonly prevText?: string;
    readonly prevIcon?: string | Component;
    readonly nextText?: string;
    readonly nextIcon?: string | Component;
    readonly hideOnSinglePage?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onUpdate:current-page"?: (val: number) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onUpdate:page-size"?: (val: number) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onSize-change"?: (val: number) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onCurrent-change"?: (val: number) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onPrev-click"?: (val: number) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    "onNext-click"?: (val: number) => any;
}

// 搜索
export interface ISearch{
    drawer: boolean,
    title: string,
    data: ISearchData,
    component: string|Component,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onConfirmSearch?:() => any
}

// 搜索具体数据
export interface ISearchData{
    [propNames:string]:string|number
}

// 分页查询参数
export interface RPageableSearch {
    pageNum?: number;
    pageNumber?: number;
    pageSize: number;
}
// 查询分页条件(蚂蚁接口规范)
export interface IMYSearch{
    // 当前叶
    page:number,
    // 分页大小
    limit:number
}

export interface RList<R,S> {
    title: string; // 列表管理名称
	columns: ColumnProps[]; // 列配置项
    rules?: FormRules; // 表单验证规则
    requestApi: (params: S) => Promise<RListData<R>>; // 请求表格数据的api ==> 必传
    deleteApi?:(id:string[])=>Promise<boolean>,// 删除列表行数据api
    treeApi?:() => Promise<any>,//获取标签管理的树形结构
    // deleteTip?:(row:R)=>string,// 删除确认提示
    // deleteTip?:(row:R)=>Promise<boolean>,// 删除确认提示
    addApi?:(row:R)=>Promise<boolean>, // 新增api
    // editApi?:(row:R)=>boolean, // 修改api
    editApi?:(row:R)=>Promise<boolean>, // 修改api
	// requestApi: (params: RPageableSearch) => Promise<T>; // 请求表格数据的api ==> 必传
	// dataCallback?: (data: T) => RListData<R>; // 返回数据的回调函数，可以对数据进行处理 ==> 非必传
	initParam?: any; // 初始化请求参数 ==> 非必传（默认为{}）
	selectId?: string; // 当表格数据多选时，所指定的 id ==> 非必传（默认为 id）
	pagination?: boolean; // 是否需要分页组件 ==> 非必传（默认为true）
	border?: boolean; // 是否带有纵向边框 ==> 非必传（默认为true）
	toolButton?: boolean; // 是否显示表格功能按钮 ==> 非必传（默认为true）
}
// 分页参数
export interface RPageable extends RPageableSearch {
    total: number;
}
// 查询参数(包含分页和查询参数)
export interface RListData<T> extends RPageable {
    list:T[],
}
