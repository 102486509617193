import { Module } from "vuex";
import { IRouterData, ISystemState,IUser } from "@/ts";
import * as Types from "../action-type";
import { IGlobalState } from "..";
import { getUser } from "@/ts/data/api/system";

const state:ISystemState = {
    user:{
        userId:"",
        userName:""
    },
    currentRouter:{
        path:"/",
        name:"首页",
        title:"首页",
        component:"/Main"
    }
}

const system:Module<ISystemState, IGlobalState> = {
    namespaced:true,
    state,
    mutations:{
        [Types.SET_LOG_ON_USER](state,payload:IUser) {
            state.user=payload
        },
        [Types.SET_CURRENT_ROUTER](state,payload:IRouterData) {
            state.currentRouter=payload
        }
    },
    actions:{
        async [Types.SET_LOG_ON_USER]({commit}) {
            const logOnUser=await getUser();
            commit(Types.SET_LOG_ON_USER, logOnUser);
        },
        [Types.SET_CURRENT_ROUTER]({commit}, currentRouter:IRouterData) {
            commit(Types.SET_CURRENT_ROUTER, currentRouter);
        }
    }
}

export default system