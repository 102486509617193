import { createStore } from "vuex";
import {ISystemState} from "@/ts"
import system from "./modules/system"

// 全局数据状态
export interface IGlobalState {
  system:ISystemState
}

export default createStore<IGlobalState>({
  // state: {},
  mutations: {},
  actions: {},
  modules: {
    system
  },
});
